@media screen and (max-width: 1280px){
    #main-header{   
    .content{       
        height: get-vw-portatil(30px);    
        #bandaazul{            
            max-width:get-vw-portatil(960px);
            ul{
                float:right;
                li{
                    padding-top:get-vw-portatil(7px);
                    padding-left:get-vw-portatil(5px);
                    padding-right:get-vw-portatil(5px);                   
                    font-size: get-vw-portatil(14px);
                }
            }
        }
    }
    .box{      
        height: get-vw-portatil(80px);    
        .content-box{
            max-width:get-vw-portatil(960px);            
            #logo{margin-top: get-vw-portatil(20px);}
            .logo-fecyt{margin-top: get-vw-portatil(20px);;margin-right: get-vw-portatil(50px);}
            .search{margin-top: get-vw-portatil(30px);margin-right: get-vw-portatil(20px);}                          
        }
    }
     .contenedor-menu{
        position: absolute;
        width: 100%;
        z-index: 9999;
        right: 0px;
        overflow-x: hidden;
        max-width: get-vw-portatil(335px);
	transition: transform 0.6s cubic-bezier(.56,.1,.34,.91);
    }
}



nav {
	width: get-vw-portatil(335px);;
    right: get-vw-portatil(-335px);;;	
	.menu-open & {
		transform: translateX(get-vw-portatil(-345px));
	}	
	ul {		
		li {			
			font-size: get-vw-portatil(18px);			
		}
	}
}
}