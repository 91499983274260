@media screen and (max-width: 800px){
    .cd-hero{
    h2{
        font-size: get-vw-tablet_768(40px);
        line-height: get-vw-tablet_768(44px);

    }
    p{
        font-size: get-vw-tablet_768(26px);
        line-height: get-vw-tablet_768(20px);
      
    }
    .cd-btn{font-size: get-vw-tablet_768(20px);}
}

.main-sectors{
 
    padding:get-vw-tablet_768(10px) 0 get-vw-tablet_768(70px) 0;
    header{
       
        margin:get-vw-tablet_768(60px) 0 get-vw-tablet_768(60px) 0;
        h3{           
            font-size:get-vw-tablet_768(18px); 
            line-height: get-vw-tablet_768(35px);
        }
        p{           
            font-size:get-vw-tablet_768(24px); 
        }
        span{margin-top:get-vw-tablet_768(10px); }
    }
    .content-recomendados{
        justify-content: space-around;
        max-width: get-vw-tablet_768(770px); 
       
        .items{
            
            max-width: get-vw-tablet_768(360px); 

            .txt{
                padding:get-vw-tablet_768(18px) get-vw-tablet_768(30px);
                h4{
            font-size:get-vw-tablet_768(18px);line-height: get-vw-tablet_768(26px); }
                p{
line-height: get-vw-tablet_768(20px);
            font-size:get-vw-tablet_768(18px);   
                }
            }
        }
    }
   
    .content-destacados{       
        max-width: get-vw-tablet_768(700px);     
        justify-content: space-around;
        .items{           
            margin-bottom: get-vw-tablet_768(30px);
            max-width: get-vw-tablet_768(300px); 
            
            .content_img{               
                .txtImg{                   
                    .fa,span{color:$blanco;padding: get-vw-tablet_768(5px);font-size:get-vw-tablet_768(14px);}
                    span{float:right;
            font-size:get-vw-tablet_768(14px); font-family: $robotobold;  }                    
                }            
            }
            .txt{
                padding:get-vw-tablet_768(18px) get-vw-tablet_768(30px);
                h4{
            font-size:get-vw-tablet_768(18px);line-height: get-vw-tablet_768(26px); }
                p{                 
            font-size:get-vw-tablet_768(18px);   
            line-height: get-vw-tablet_768(20px);   
                }
            }
        }
    }
    .content-all{       
         max-width:get-vw-tablet_768(700px);   
         justify-content: space-around;
        .items{                        
            margin-bottom: get-vw-tablet_768(30px);
            max-width: get-vw-tablet_768(300px);            
            .content_img{               
                .txtImg{                  
                    .fa,span{padding: get-vw-tablet_768(5px);font-size:get-vw-tablet_768(14px)!important;}
                    span{
            font-size:get-vw-tablet_768(14px); }                    
                }            
            }
            .txt{
                padding:get-vw-tablet_768(18px) get-vw-tablet_768(30px);
                h4{
            font-size:get-vw-tablet_768(18px);line-height: get-vw-tablet_768(26px); }
                p{                 
            font-size:get-vw-tablet_768(18px);   
                }
            }                        
        }
        
                  
    }
    
}
#contenedor_detalle
{
    .contenido_video{
             
                max-width: get-vw-tablet_768(700px); 
                height: 700px;
                padding-top: get-vw-tablet_768(50px); 
                padding-bottom: get-vw-tablet_768(50px); 
                align-self:center;
                width: get-vw-tablet_768(700px); 
                .video{
                    width: get-vw-tablet_768(320px);
                    max-width: get-vw-tablet_768(320px); 
                    video{ max-width: get-vw-tablet_768(320px); }
                    
                }
                .content_texto_video{
                    width: get-vw-tablet_768(320px);
                    max-width: get-vw-tablet_768(320px); 
                    border-top: 3px solid $azul;
                    padding: get-vw-tablet_768(30px); 

                    h5{                      
                       font-size:get-vw-tablet_768(18px);  
                       margin-bottom:get-vw-tablet_768(15px);                       
                    }
                    p{font-size:get-vw-tablet_768(14px);line-height:get-vw-tablet_768(16px); }
                    .masinfo{margin-top:  get-vw-tablet_768(20px);font-size:get-vw-tablet_768(14px);}
                    .lieasepara{margin-top:get-vw-tablet_768(15px); }
                    .bloque_detalles{                        
                        .bloques{ width: 100%;margin-top:get-vw-tablet_768(20px);
                                 p{
                                     line-height: get-vw-tablet_768(14px);                                    
                                 }
                                 .contenedor_centrado{                                     
                                        div{font-size: get-vw-tablet_768(10px);}
                                 }
                        }
                        .descargas{
                            a{padding-top:get-vw-tablet_768(12px);padding-bottom: get-vw-tablet_768(12px);margin-bottom:get-vw-tablet_768(10px);
                              font-size: get-vw-tablet_768(14px);
                            }
                        }
                        .redes{font-size:get-vw-tablet_768(30px);align-self:center;                            
                        }
                       
                    }
                    
                }
                #close{color:$gris2;font-size: get-vw-tablet_768(24px);}
                .etiquetas{  
font-size: get-vw-tablet_768(12px);                    
                       margin-top: get-vw-tablet_768(30px);      
                        
                        div{
                             
                            margin-right: get-vw-tablet_768(20px);     
                            margin-bottom: get-vw-tablet_768(30px);
                            a{font-size: get-vw-tablet_768(12px);padding:get-vw-tablet_768(3px) get-vw-tablet_768(10px); }
                        }
                        
                    }
            }
}
}