@font-face {
  font-family: 'robotoregular';
  src: url('fonts/roboto-regular-webfont.eot');
  src: url('fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/roboto-regular-webfont.woff2') format('woff2'), url('fonts/roboto-regular-webfont.woff') format('woff'), url('fonts/roboto-regular-webfont.ttf') format('truetype'), url('fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotomedium';
  src: url('fonts/roboto-medium-webfont.eot');
  src: url('fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/roboto-medium-webfont.woff2') format('woff2'), url('fonts/roboto-medium-webfont.woff') format('woff'), url('fonts/roboto-medium-webfont.ttf') format('truetype'), url('fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotolight';
  src: url('fonts/roboto-light-webfont.eot');
  src: url('fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/roboto-light-webfont.woff2') format('woff2'), url('fonts/roboto-light-webfont.woff') format('woff'), url('fonts/roboto-light-webfont.ttf') format('truetype'), url('fonts/roboto-light-webfont.svg#robotolight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotobold';
  src: url('fonts/roboto-bold-webfont.eot');
  src: url('fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/roboto-bold-webfont.woff2') format('woff2'), url('fonts/roboto-bold-webfont.woff') format('woff'), url('fonts/roboto-bold-webfont.ttf') format('truetype'), url('fonts/roboto-bold-webfont.svg#robotobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfont.eot');
  src: url('fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/fontawesome-webfont.woff2') format('woff2'), url('fonts/fontawesome-webfont.woff') format('woff'), url('fonts/fontawesome-webfont.ttf') format('truetype'), url('fonts/fontawesome-webfont.svg#robotobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

$iconos:'FontAwesome';

$robotobold:'robotobold';
$robotolight:'robotolight';
$robotomedium:'robotomedium';
$robotoregular:'robotoregular';