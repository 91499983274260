footer{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    background: #68be5e;  
    padding-top: get-vw(80px); 
    
   flex-wrap: wrap;
    
    .content_bloques{
        display: flex;
        width: 100%;
        max-width: get-vw(950px); 
        flex-wrap: wrap;
        align-items: top;
        justify-content: space-between;
        padding-bottom: get-vw(80px); 
        .bloques{
            width: 100%;
            max-width: get-vw(270px); 
            ul{
                display: block;
                width: 100%;
                li{
                    display: block;
                    width: 100%;
                    color:$blanco;
                        font-size: get-vw(14px); 
                    font-family: $robotobold;
                    border-bottom: 1px solid $blanco;
                    h3{
                        font-size: get-vw(18px); 
                        font-family: $robotobold;
                        color:$blanco;
                        padding-bottom: get-vw(15px); 
                    }
                    a{
                        display: block;
                        width: 100%;
                        padding-top: get-vw(15px); 
                        padding-bottom: get-vw(15px); 
                        color:$blanco;
                        font-size: get-vw(18px); 
                        font-family: $robotolight;
                    }  
                    #enviarcontacto{font-size: get-vw(15px); border: 2px solid $blanco;padding: get-vw(15px) get-vw(25px);width: auto!important;cursor: pointer;background: transparent;color:$blanco;font-family: $robotobold;}
                    #mail{background: $blanco;width: 100%;margin-top: get-vw(10px);border: none;padding: get-vw(15px) 0; margin-bottom: get-vw(15px);}
                }
                .formulario:nth-of-type(2) {
                        border: none;
                        text-align: left;
                }
                .formulario:nth-of-type(3) {
                    border: none;
                    text-align: left;
                }
                .formulario:nth-of-type(4) {
                    border: none;
                    text-align: left;
                }
            }
        }
    }
    .content_final{
        display: flex;
        justify-content: center;
        width: 100%;
        background: $blanco;
        padding: get-vw(40px) 0; 
        flex-wrap: wrap;
        .contenido_medio{
            display: inline-block;
            max-width: get-vw(1400px); 
            width: 100%;
            .logos{
                float:left;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-end;
            }
            .redes{
                margin-top: get-vw(50px);
                font-size: get-vw(20px);
                float:right;
                
                a{color:$gris2;margin-right:get-vw(25px);  }
            }
        }
    }
    
}