@media screen and (max-width: 1280px){
    footer{   
    padding-top: get-vw-portatil(80px); 
    .content_bloques{      
        max-width: get-vw-portatil(950px);        
        padding-bottom: get-vw-portatil(80px); 
        .bloques{
           width: 100%;
            max-width: get-vw-portatil(270px); 
            ul{             
                li{                  
                        font-size: get-vw-portatil(14px);                    
                    h3{
                        font-size: get-vw-portatil(18px);                        
                        padding-bottom: get-vw-portatil(15px); 
                    }
                    a{
                        
                        padding-top: get-vw-portatil(15px); 
                        padding-bottom: get-vw-portatil(15px); 

                        font-size: get-vw-portatil(18px); 
                       
                    }  
                    #enviarcontacto{font-size: get-vw-portatil(15px); border: 2px solid $blanco;padding: get-vw-portatil(15px) get-vw-portatil(25px);width: auto!important;cursor: pointer;background: transparent;color:$blanco;font-family: $robotobold;}
                    #mail{background: $blanco;width: 100%;margin-top: get-vw-portatil(10px);border: none;padding: get-vw-portatil(15px) 0; margin-bottom: get-vw-portatil(15px);}
                }               
            }
        }
    }
    .content_final{
      
        padding: get-vw-portatil(40px) 0; 
       
        .contenido_medio{
          
            max-width: get-vw-portatil(1400px); 
           
           
            .redes{
                margin-top: get-vw-portatil(50px);
                font-size: get-vw-portatil(20px);
               
                
                a{color:$gris2;margin-right:get-vw-portatil(25px);  }
            }
        }
    }
    
}
}