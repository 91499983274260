.cd-hero{
    h2{
        font-size: get-vw(60px);
        line-height: get-vw(64px);
        color:$blanco;
        font-family: $robotobold;
    }
    p{
        font-size: get-vw(36px);
        line-height: get-vw(40px);
        color:$blanco;
        font-family: $robotolight;
    }
    .cd-btn{font-size: get-vw(20px);font-family: $robotolight;color:$blanco;}
}

.main-sectors{
    display: block;
    width: 100%;
    padding:get-vw(10px) 0 get-vw(70px) 0;
    header{
        text-align: center;
        margin:get-vw(60px) 0 get-vw(60px) 0;
        h3{
            font-family: $robotobold;
            font-size:get-vw(18px); 
            line-height: get-vw(30px);
        }
        p{
            font-family: $robotolight;
            font-size:get-vw(24px); 
        }
        span{width: 100%;max-width: 300px;border-bottom: 1px solid #c2c2c2;display: inline-block;height: 10px;margin-top:get-vw(10px); }
    }
    .basic_conten{
         display:flex;
        position:relative;
        flex-wrap: wrap;
        width: 100%;
        max-width: get-vw(970px); 
        margin: auto;
        align-items: top;
        justify-content: space-between;
        p{
             font-family: $robotoregular;
            font-size:get-vw(16px);  
            line-height: get-vw(22px);
        }
    }
    .content-recomendados{
        display:flex;
        position:relative;
        flex-wrap: wrap;
        width: 100%;
        max-width: get-vw(970px); 
        margin: auto;
        align-items: top;
        justify-content: space-between;
        .items{
            width: 100%;
            -webkit-box-shadow: 0px 5px 10px 0px rgba(226,226,226,1);
-moz-box-shadow: 0px 5px 10px 0px rgba(226,226,226,1);
box-shadow: 0px 5px 10px 0px rgba(226,226,226,1);
            max-width: get-vw(460px); 
            .content_img{display: block;width: 100%;}
            .txt{
                padding:get-vw(18px) get-vw(30px);
                h4{font-family: $robotobold;
            font-size:get-vw(18px);line-height: get-vw(26px); }
                p{
                  font-family: $robotoregular;
            font-size:get-vw(18px);   
                }
            }
        }
    }
   
    .content-destacados{
        display:flex;
        position:relative;
        flex-wrap: wrap;
        width: 100%;
        max-width: get-vw(1090px); 
        margin: auto;
        align-items: top;
        justify-content: space-between;
        .items{
            width: 100%;
            -webkit-box-shadow: 0px 5px 10px 0px rgba(226,226,226,1);
-moz-box-shadow: 0px 5px 10px 0px rgba(226,226,226,1);
box-shadow: 0px 5px 10px 0px rgba(226,226,226,1);
            margin-bottom: get-vw(30px);
            cursor: pointer;
            max-width: get-vw(346px); 
            .content_img{
                float:left;
                position: relative;
                width: 100%;
                img{float:left;width: 100%;}
                .txtImg{
                    width: 100%;
                    background: rgba(0, 0, 0, .4); 
                    position: absolute;
                    bottom: 0px;
                    
                    color:$blanco;
                    .fa,span{color:$blanco;padding: get-vw(5px);}
                    span{float:right;
            font-size:get-vw(14px); font-family: $robotobold;  }
                    
                }
            
            }
            .txt{
                float:left;
                padding:get-vw(18px) get-vw(30px);
                h4{font-family: $robotobold;
            font-size:get-vw(18px);line-height: get-vw(26px); }
                p{
                  font-family: $robotoregular;
            font-size:get-vw(18px);   
                }
            }
        }
    }
    .content-all{
        display:flex;
        position:relative;
        flex-wrap: wrap;
        width: 100%;
         max-width:get-vw(1840px);
        margin: auto;
        align-items: top;
        justify-content: center;
        .items{
            cursor: pointer;
            width: 100%;
            margin-right:get-vw(5px); 
            margin-left: get-vw(5px);
            margin-bottom: get-vw(20px);
            max-width: get-vw(346px); 
            border: 3px solid transparent;
            align-self: baseline;
            position:relative;
            .content_img{
                
                float:left;
                position: relative;
                width: 100%;      
                img{float:left;}
                .txtImg{
                    width: 100%;
                    background: rgba(0, 0, 0, .4); 
                    position: absolute;
                    bottom: 0px;                    
                    color:$blanco;
                    .fa,span{color:$blanco;padding: get-vw(5px);}
                    span{float:right;
                    font-size:get-vw(14px); font-family: $robotobold;  }                    
                }
            
            }
            .txt{
                padding:get-vw(18px) get-vw(30px);
                h4{font-family: $robotobold;
            font-size:get-vw(18px);line-height: get-vw(26px); }
                p{
                  font-family: $robotoregular;
            font-size:get-vw(18px);   
                }
            } 
            &:hover{border: 3px solid $azul;}
        }
        
                  
    }
    
}
#contenedor_detalle
{
    text-align: center;
    display:none; position:absolute; background:#fff; width:100%;
    .contenido_video{
        
                display: inline-flex;
                flex-wrap: wrap;
                align-items: top;
                justify-content: space-between;
                max-width: get-vw(1200px); 
                min-height: get-vw(500px);
                padding-top: get-vw(50px); 
                padding-bottom: get-vw(50px); 
                position: relative;
                z-index: 99999;
                width: get-vw(1200px); 
                .video{
                    width: get-vw(640px);
                    max-width: get-vw(640px); 
                    video{ max-width: get-vw(640px); }
                    
                }
                .content_texto_video{
                    width: get-vw(500px);
                    max-width: get-vw(500px); 
                    border-top: 3px solid $azul;
                    padding: get-vw(30px) get-vw(30px) get-vw(10px) get-vw(30px) ; 
-webkit-box-shadow: 0px 11px 10px 0px rgba(181,181,181,1);
-moz-box-shadow: 0px 11px 10px 0px rgba(181,181,181,1);
box-shadow: 0px 11px 10px 0px rgba(181,181,181,1);
                    h5{
                       font-family: $robotobold; 
                       font-size:get-vw(18px);  
                       margin-bottom:get-vw(15px); 
                       .fa{color:$azul;}
                    }
                    p{font-size:get-vw(14px); color:$gris2;float:left;width: 100%;
                        b{font-family: $robotobold; }
                        strong{font-family: $robotobold; }
                        i{font-style:  italic!important;}
                    }
                    .masinfo{text-decoration: underline;margin-top:  get-vw(20px);float:left;   }
                    .lieasepara{width: 100%;display: inline-block;height: 10px;border-bottom: 1px solid $gris2;margin-top:get-vw(15px); }
                    .bloque_detalles{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: top;
                        justify-content: space-between;
                        .bloques{ width: get-vw(220px);margin-top:get-vw(10px);
                                 p{
                                     line-height: get-vw(14px);
                                     b{color:$negro;}
                                 }
                                 .contenedor_centrado{
                                      display: flex;
                                        flex-wrap: wrap;
                                        align-items: center;
                                        div{margin-right: 2px;font-size: get-vw(10px);}
                                 }
                        }
                        .descargas{
                            a{display: block;width: 100%; text-align: center;color:$blanco;padding-top:get-vw(12px);padding-bottom: get-vw(12px);;background: #b5b5b5;margin-bottom:get-vw(5px);
                              
                            }
                            a:hover {
                                  background: #209bde!important;
                              }
                        }
                        .redes{text-align: center;font-size:get-vw(30px);align-self:center;
                              a{color:#b5b5b5;  }
                              a:hover {
                                  color: #209bde!important;
                              }
                        }
                       
                    }
                    
                }
                #close{color:$gris2;font-size: get-vw(24px);}
                .etiquetas{
                        width: 100%;
                        align-self:center;
                        text-align: center;
                        display: flex;
                        margin-top: get-vw(30px);
                        flex-wrap: wrap;                        
                        div{
                            
                        margin-bottom: get-vw(30px);
                            margin-right: get-vw(20px);
                            align-self:center;
                            a{border: 1px solid $gris2;color:$gris2;font-size: get-vw(12px);padding:get-vw(3px) get-vw(10px); }
                            a:hover {
                                  color: #209bde!important;
                              }
                        }
                        
                    }
            }
}