@media screen and (max-width: 800px){
    #main-header{   
    .content{       
        height: get-vw-tablet_768(30px);    
        #bandaazul{            
            max-width:get-vw-tablet_768(750px);
            ul{
                float:right;
                li{
                    padding-top:get-vw-tablet_768(7px);
                    padding-left:get-vw-tablet_768(5px);
                    padding-right:get-vw-tablet_768(5px);                   
                    font-size: get-vw-tablet_768(14px);
                }
            }
        }
    }
    .box{      
        height: auto;    
        .content-box{
            padding-bottom: get-vw-tablet_768(20px);       
            max-width:get-vw-tablet_768(750px);            
            #logo{margin-top: get-vw-tablet_768(20px);float:none;}
            .logo-fecyt{max-width: 70%;margin-top: get-vw-tablet_768(20px);;margin-right: get-vw-tablet_768(50px);float:none;
            img{width: 70%;}
            }
            .search{margin-top: get-vw-tablet_768(30px);margin-right: get-vw-tablet_768(20px);}                          
        }
    } 
    .contenedor-menu{
        position: absolute;
        width: 100%;
        z-index: 9999;
        right: 0px;
        overflow-x: hidden;
        max-width: get-vw-tablet_768(335px);
	transition: transform 0.6s cubic-bezier(.56,.1,.34,.91);
    }
}



nav {
	width: get-vw-tablet_768(335px);;
    right: get-vw-tablet_768(-335px);;;	
	.menu-open & {
		transform: translateX(get-vw-tablet_768(-345px));
	}	
	ul {		
		li {			
			font-size: get-vw-tablet_768(18px);			
		}
	}
}
}