$negro:#333333;
$negro2:#111623;
$verde:#54b797;
// $amarillo:#f5e642;


$amarillo:#f5e642;


$marron:#918574;
$gris:#f5f5f5;
$gris2:#666666;
$azul:#209bde;
$azul2:#417EB7;

$fill:#F4F4F4;
$greyBright:#f4f4f4;
$blanco:#FFF;



/*vars*/
$bezier1: cubic-bezier(0.23, 1, 0.32, 1);
$bezier2:cubic-bezier(.19,1,.22,1); 
$gw-wraper: 1500px;


$max-txt-limit: 1900px;



@smartphones: ~"only screen and (max-width: 479px)";
@tablet: ~"only screen and (max-width: 768px)";

