@media screen and (max-width: 1024px){
    .cd-hero{
    h2{
        font-size: get-vw-tablet(60px);
        line-height: get-vw-tablet(64px);

    }
    p{
        font-size: get-vw-tablet(36px);
        line-height: get-vw-tablet(40px);
      
    }
    .cd-btn{font-size: get-vw-tablet(20px);}
}

.main-sectors{
 
    padding:get-vw-tablet(10px) 0 get-vw-tablet(70px) 0;
    header{
       
        margin:get-vw-tablet(60px) 0 get-vw-tablet(60px) 0;
        h3{           
            font-size:get-vw-tablet(18px); 
            line-height: get-vw-tablet(30px);
        }
        p{           
            font-size:get-vw-tablet(24px); 
        }
        span{margin-top:get-vw-tablet(10px); }
    }
    .basic_conten{

        max-width: get-vw-tablet(970px); 
        p{
             font-family: $robotoregular;
            font-size:get-vw-tablet(16px);  
            line-height: get-vw-tablet(22px);
        }
    }
    .content-recomendados{
      
        max-width: get-vw-tablet(970px); 
       
        .items{
            
            max-width: get-vw-tablet(460px); 

            .txt{
                padding:get-vw-tablet(18px) get-vw-tablet(30px);
                h4{
            font-size:get-vw-tablet(18px);line-height: get-vw-tablet(26px); }
                p{

            font-size:get-vw-tablet(18px);   
                }
            }
        }
    }
   
    .content-destacados{       
        max-width: get-vw-tablet(960px);        
        .items{           
            margin-bottom: get-vw-tablet(30px);
            max-width: get-vw-tablet(300px); 
            .content_img{               
                .txtImg{                   
                    .fa,span{color:$blanco;padding: get-vw-tablet(5px);}
                    span{float:right;
            font-size:get-vw-tablet(14px); font-family: $robotobold;  }                    
                }            
            }
            .txt{
                padding:get-vw-tablet(18px) get-vw-tablet(30px);
                h4{
            font-size:get-vw-tablet(18px);line-height: get-vw-tablet(26px); }
                p{                 
            font-size:get-vw-tablet(18px);   
            line-height: get-vw-tablet(20px);   
                }
            }
        }
    }
    .content-all{       
         max-width:get-vw-tablet(960px);       
        .items{                        
            margin-bottom: get-vw-tablet(30px);
            max-width: get-vw-tablet(300px);            
            .content_img{               
                .txtImg{                  
                    .fa,span{padding: get-vw-tablet(5px);}
                    span{
            font-size:get-vw-tablet(14px); }                    
                }            
            }
            .txt{
                padding:get-vw-tablet(18px) get-vw-tablet(30px);
                h4{
            font-size:get-vw-tablet(18px);line-height: get-vw-tablet(26px); }
                p{                 
            font-size:get-vw-tablet(18px);   
                }
            }                        
        }
        
                  
    }
    
}

#contenedor_detalle
{
   .contenido_video{
             
                max-width: get-vw-tablet(960px); 
                min-height: 700px;
                padding-top: get-vw-tablet(50px); 
                padding-bottom: get-vw-tablet(50px); 
                align-self:center;
                width: get-vw-tablet(960px); 
                .video{
                    width: get-vw-tablet(440px);
                    max-width: get-vw-tablet(440px); 
                    video{ max-width: get-vw-tablet(440px); }
                    
                }
                .content_texto_video{
                    width: get-vw-tablet(440px);
                    max-width: get-vw-tablet(440px); 
                    border-top: 3px solid $azul;
                    padding: get-vw-tablet(30px); 

                    h5{                      
                       font-size:get-vw-tablet(18px);  
                       margin-bottom:get-vw-tablet(15px);                       
                    }
                    p{font-size:get-vw-tablet(14px);line-height:get-vw-tablet(16px); }
                    .masinfo{margin-top:  get-vw-tablet(20px);font-size:get-vw-tablet(14px);}
                    .lieasepara{margin-top:get-vw-tablet(15px); }
                    .bloque_detalles{                        
                        .bloques{ width: get-vw-tablet(160px);margin-top:get-vw-tablet(20px);
                                 p{
                                     line-height: get-vw-tablet(14px);                                    
                                 }
                                 .contenedor_centrado{                                     
                                        div{font-size: get-vw-tablet(10px);}
                                 }
                        }
                        .descargas{
                            a{padding-top:get-vw-tablet(12px);padding-bottom: get-vw-tablet(12px);margin-bottom:get-vw-tablet(10px);
                              font-size: get-vw-tablet(14px);
                            }
                        }
                        .redes{font-size:get-vw-tablet(30px);align-self:center;                            
                        }
                       
                    }
                    
                }
                #close{color:$gris2;font-size: get-vw-tablet(24px);}
                .etiquetas{  
font-size: get-vw-tablet(12px);                    
                       margin-top: get-vw-tablet(30px);      
                        
                        div{
                             
                            margin-right: get-vw-tablet(20px);     
                            margin-bottom: get-vw-tablet(30px);
                            a{font-size: get-vw-tablet(12px);padding:get-vw-tablet(3px) get-vw-tablet(10px); }
                        }
                        
                    }
            } 
}

}