@import "reset";


@import "_variables";
@import "_mixins";
@import "_fonts"; 
  
//desktop 

@import "_header";
@import "_home"; 
@import "_footer"; 

//portatil

@import "portatil/_header";
@import "portatil/_home"; 
@import "portatil/_footer"; 

//tablet

@import "tablet/_header";
@import "tablet/_home"; 
@import "tablet/_footer"; 

//tablet 768

@import "tablet_768/_header";
@import "tablet_768/_home"; 
@import "tablet_768/_footer"; 

//mobile

@import "mobile/_header";
@import "mobile/_home"; 
@import "mobile/_footer"; 

body{
	background:white;
	color:$negro;
    font-family: $robotoregular;
    font-size:get-vw(14px);
    line-height: get-vw(18px);
}
.fondogris{background: $gris;}
a{color:$azul;}
.video_oculto{display: none;}
@media screen and (max-width: 620px){
    body{

    font-size:get-vw-mobile(14px);
    line-height: get-vw-mobile(18px);
}
}

