@media screen and (max-width: 620px){
    .cd-hero{
    h2{
        font-size: get-vw-mobile(30px);
        line-height: get-vw-mobile(34px);

    }
    p{
        font-size: get-vw-mobile(16px);
        line-height: get-vw-mobile(20px);
      
    }
    .cd-btn{font-size: get-vw-mobile(16px);}
}

.main-sectors{
 
    padding:get-vw-mobile(10px) 0 get-vw-mobile(70px) 0;
    header{
       
        margin:get-vw-mobile(60px) 0 get-vw-mobile(60px) 0;
        h3{           
            font-size:get-vw-mobile(18px); 
            line-height: get-vw-mobile(35px);
        }
        p{           
            font-size:get-vw-mobile(24px); 
            line-height: get-vw-mobile(26px);
        }
        span{margin-top:get-vw-mobile(10px); }
    }
     .basic_conten{

        max-width: 90%; 
         p{
             font-family: $robotoregular;
            font-size:get-vw-mobile(16px);  
            line-height: get-vw-mobile(22px);
        }
       
    }
    .content-recomendados{
        justify-content: space-around;
        max-width: get-vw-mobile(770px); 
       
        .items{
            
            max-width: get-vw-mobile(360px); 

            .txt{
                padding:get-vw-mobile(18px) get-vw-mobile(30px);
                h4{
            font-size:get-vw-mobile(18px);line-height: get-vw-mobile(26px); }
                p{
line-height: get-vw-mobile(20px);
            font-size:get-vw-mobile(18px);   
                }
            }
        }
    }
   
    .content-destacados{       
        max-width: get-vw-mobile(700px);     
        justify-content: space-around;
        .items{           
            margin-bottom: get-vw-mobile(30px);
            max-width: get-vw-mobile(346px); 
            
            .content_img{               
                .txtImg{                   
                    .fa,span{color:$blanco;padding: get-vw-mobile(5px);font-size:get-vw-mobile(14px);}
                    span{float:right;
            font-size:get-vw-mobile(14px); font-family: $robotobold;  }                    
                }            
            }
            .txt{
                padding:get-vw-mobile(18px) get-vw-mobile(30px);
                h4{
            font-size:get-vw-mobile(18px);line-height: get-vw-mobile(26px); }
                p{                 
            font-size:get-vw-mobile(18px);   
            line-height: get-vw-mobile(20px);   
                }
            }
        }
    }
    .content-all{       
         max-width:get-vw-mobile(700px);   
         justify-content: space-around;
        .items{                        
            margin-bottom: get-vw-mobile(30px);
            max-width: get-vw-mobile(346px);            
            .content_img{               
                .txtImg{                  
                    .fa,span{padding: get-vw-mobile(5px);font-size:get-vw-mobile(14px)!important;}
                    span{
            font-size:get-vw-mobile(14px); }                    
                }            
            }
            .txt{
                padding:get-vw-mobile(18px) get-vw-mobile(30px);
                h4{
            font-size:get-vw-mobile(18px);line-height: get-vw-mobile(26px); }
                p{                 
            font-size:get-vw-mobile(18px);   
                }
            }                        
        }
        
                  
    }
    
}

#contenedor_detalle
{
  .contenido_video{
             
                max-width: 90%; 
                height: auto;
                padding-top: get-vw-mobile(50px); 
                padding-bottom: get-vw-mobile(50px); 
                align-self:center;
                width: 100%; 
                .video{
                    width: 100%;
                    max-width: 100%;
                    video{ max-width: 100%; }
                    
                }
                .content_texto_video{
                    margin-top: 10px;
                    width: 90%;;
                    max-width: 90%;; 
                    border-top: 3px solid $azul;
                    padding: get-vw-mobile(30px); 

                    h5{                      
                       font-size:get-vw-mobile(18px);  
                       margin-bottom:get-vw-mobile(15px);                       
                    }
                    p{font-size:get-vw-mobile(14px);line-height:get-vw-mobile(16px); }
                    .masinfo{margin-top:  get-vw-mobile(20px);font-size:get-vw-mobile(14px);}
                    .lieasepara{margin-top:get-vw-mobile(15px); }
                    .bloque_detalles{                        
                        .bloques{ width: 100%;margin-top:get-vw-mobile(20px);
                                 p{
                                     line-height: get-vw-mobile(14px);                                    
                                 }
                                 .contenedor_centrado{                                     
                                        div{font-size: get-vw-mobile(10px);}
                                 }
                        }
                        .descargas{
                            a{padding-top:get-vw-mobile(12px);padding-bottom: get-vw-mobile(12px);margin-bottom:get-vw-mobile(10px);
                              font-size: get-vw-mobile(14px);
                            }
                        }
                        .redes{font-size:get-vw-mobile(30px);align-self:center;                            
                        }
                       
                    }
                    
                }
                #close{color:$gris2;font-size: get-vw-mobile(24px);float: right;}
                .etiquetas{  
font-size: get-vw-mobile(12px);                    
                       margin-top: get-vw-mobile(30px);      
                        
                        div{
                             
                            margin-right: get-vw-mobile(20px);     
                            margin-bottom: get-vw-mobile(30px);
                            a{font-size: get-vw-mobile(12px);padding:get-vw-mobile(3px) get-vw-mobile(10px); }
                        }
                        
                    }
            }  
}

}