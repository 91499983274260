#main-header{
    display: block;
    width: 100%;
    .content{
        display: flex;
        justify-content: center;
        flex-wrap:wrap;
        width: 100%;        
        background: $azul;
        height: get-vw(30px);    
        #bandaazul{            
            color:$blanco;
            width: 100%;
            max-width:get-vw(1840px);
            ul{
                float:right;
                li{
                    display: inline-block;
                    list-style: none;
                    padding-top:get-vw(7px);
                    padding-left:get-vw(5px);
                    padding-right:get-vw(5px);
                    font-family: $robotobold;
                    font-size: get-vw(14px);
                    a{text-decoration: none;color:$blanco;}
                }
            }
        }
    }
    .box{
        display: inline-block;
        width: 100%;        
        background:url(../img/fondopajaros.jpg) #fefefe no-repeat center center;
        text-align: center;
        min-height: 80px;    
        .content-box{
            color:$blanco;
            width: 100%;
            max-width:get-vw(1840px);
            display: inline-block;
            
            #logo{float:left;margin-top: get-vw(20px);}
            .logo-fecyt{float:right;margin-top: get-vw(20px);;margin-right: get-vw(50px);}
            .search{float:right;margin-top: get-vw(30px);margin-right: get-vw(20px);
                    .content-form-search{
                        display: none;
                        z-index: 9999;
                        background: $blanco;
                        position: absolute;
                        min-width: 200px;
                        right: 0px;
                        padding: get-vw(5px);
                        .titulo{
                            padding: get-vw(5px);
                            border: 1px solid #888888;
                            float: left;
                            width: 100%;
                        }
                        #buscarBtn{
                            cursor: pointer;
                            border: none;
                            padding: get-vw(5px);
                            margin-top: get-vw(5px);
                            background: #888888;
                            color:$blanco;
                            float: left;
                        }
                    }
            }   
            
            #toggle {
                float:right;
                width: 38px;
                height: 30px;
                padding: 5px;
                margin-top:15px;
                span:after,span:before {
                content: "";
                position: absolute;
                left: 0;
                top: -9px;
                }
                span:after{
                top: 9px;
                }
                span {
                  position: relative;
                  display: block;
                  
                }
                span,span:after,span:before {
                  width: 100%;
                  height: 5px;
                  background-color: #888;
                  transition: all 0.3s;
                  backface-visibility: hidden;
                  border-radius: 2px;
                }
            }
        }
    }


    /* on activation */
    #toggle.on span {
      background-color: transparent;
    }
    #toggle.on span:before {
      transform: rotate(45deg) translate(5px, 5px);
    }
    #toggle.on span:after {
      transform: rotate(-45deg) translate(7px, -8px);
    }
    .contenedor-menu{
        position: absolute;
        width: 100%;
        z-index: 9999;
        right: 0px;
        overflow-x: hidden;
        max-width: get-vw(335px);
	transition: transform 0.6s cubic-bezier(.56,.1,.34,.91);
    }
}

.menu {
	transition: 0.1s transform linear;
	position: relative;
	background: 0;

cursor: pointer;
	z-index: 1;
	outline: 0;
	padding: 0;
	border: 0;
}

.bar {
	&, &::before, &::after {
		transition: 0.2s background linear 0.1s, 0.2s top linear 0.2s, 0.2s transform linear;
		position: absolute;
		background: #000;
		margin: auto;
		width: 100%;
		height: 0.3rem;
		content: '';
		top: 50%;
		left: 0;
	}

	& {
		margin-top: -0.2rem
	}

	&::before {
		top: -1.2rem;
	}

	&::after {
		top: 1.2rem;
	}

	&::before,
	&::after {
		transform: rotate(0deg);
	}

	.active & {
		background: 0;

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		&::before,
		&::after {
			top: 0;
		}

		&,
		&::before,
		&::after {
			transition: 0.2s background linear 0.1s, 0.2s top linear, 0.2s transform linear 0.2s;
		}
	}
}

nav {
	width: get-vw(335px);;
    right: get-vw(-335px);;;
	background:url(../img/fondomenu.png)  no-repeat top center;
	position: relative;
    z-index: 999;
	transform: translateX(250px);
	transition: transform 0.6s cubic-bezier(.56,.1,.34,.91);
	padding-top: get-vw(10px);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
	.menu-open & {
		transform: translateX(get-vw(-345px));
	}
	
	ul {
		margin: 0;
		list-style: none;
        text-align: center;
		padding: 0;		
        margin-top:30px;
        margin-bottom:30px;
		li {
			padding: 20px 5px 15px 5px;
            display: inline-block;
            width: 80%;
            border-bottom: 1px solid #666666;
            transition: transform 0.6s cubic-bezier(.56,.1,.34,.91);
			font-size: get-vw(18px);
			font-family: $robotolight;
			&:hover {
				border-bottom: 2px solid #666666;
                transition: transform 0.6s cubic-bezier(.56,.1,.34,.91);
			}
            &:last-child{border: none;}
            a{text-decoration: none;color:$negro;display: inline-block;width: 100%;text-align: center;}
		}
        .titular{font-family: $robotobold;color:$negro;}
	}
}
